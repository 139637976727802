// Libs.
import React from 'react';
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
// Components.
import ImageHero from '../common/image-hero/image-hero';
import Products from '../common/products/products';
import FindStoreCta from '../common/find-store-cta/find-store-cta';
import DepartmentSection from "../common/department-section/department-section";
import ClassListingFeatured from '../class/class-listing-featured';
import CampaignRecipes from './campaign-recipes';
import SignUp from '../common/signup/signup';
// Assets.
import styles from './campaign-landing.module.scss';
import {useCampaignLandingStaticQuery} from "../../hooks/useCampaignLandingStaticQuery";
import {formatFieldsValue} from "../../libs/formatFromGraph";
import SEO from "../../components/layout/seo";
import Layout from '../../components/layout/layout';

const CampaignLanding = ( variation = 'md') => {
  const data = formatFieldsValue(useCampaignLandingStaticQuery());
  const featuredClassId = data.featuredClass.field.nid;
  const [{ byClassNid }] = useClassesStaticQuery();
  const classSchedule = byClassNid.find((Class) => {
    return Class.class.nid === featuredClassId;
  });
// console.log(data);
  return (
    <Layout>
      <SEO title="Campaign" />
      <div className={styles.campaignLanding}>
        {data.hero.publish && <ImageHero data={data.hero} />}
        {data.products.publish && <Products data={data.products} />}
        {data.cta.publish && <FindStoreCta cta={data.cta} />}
        {data.department.publish && <DepartmentSection content={data.department.field}/>}
        {data.featuredClass.publish && classSchedule && <ClassListingFeatured classSchedule={classSchedule} variation={'sm'} />}
        {data.recipes.publish && <CampaignRecipes data={data.recipes} />}
        {data.signUp.publish && <SignUp signUp={data.signUp} />}
      </div>
    </Layout>
  );
};

export default CampaignLanding;
